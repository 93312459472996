import React from 'react';


const Footer = () => {
  return (
    <div className='py-8 text-white border-5 border-t'>
      <h2 className="text-center">Developed By Jovan Sihota</h2>
    </div>
  );
};

export default Footer;
